<template>
  <div class="product-containter">
    <nav-menu></nav-menu>
    <div class="product-body">
      <div class="product-apply flex-a flex-b">
        <div class="product-left">
          <!-- <div>标后贷产品</div>
          <div>高额度、广覆盖、无抵押、手续简、秒放款的基于中标项目的信贷产品</div> -->
        </div>
        <!-- <div class="product-right" @click="apply">立即申请</div> -->
        <div class="apply-btn" @click="apply"><img src="./../assets/apply.svg" alt=""></div>
      </div>
      <!-- <div class="containter">
        <div class="advance-box">
          <div class="title">中标即可贷</div>
          <div class="content flex-b flex-a">
            <div class="left">
              <div>最高<span>300</span>万元</div>
              <div>年化7%起<span>（借1万用一天仅2元）</span></div>
              <div>先息后本 随借随发</div>
            </div>
            <div class="right">
              <div><img src="./../assets/code_img.jpg" alt=""></div>
              <div>支付宝扫码 立即申请</div>
            </div>
          </div>
        </div>
      </div> -->
      <div class="containter">
        <div class="advance-box">
          <div class="title">四大产品优势</div>
          <div class="advance-list flex-a flex-b">
            <div class="advance-item">
              <div><img src="./../assets/advanced_ico1.png" alt=""></div>
              <div>放款快速</div>
              <div>中标即可申请最快3分钟放款</div>
            </div>
            <div class="advance-item">
              <div><img src="./../assets/advanced_ico2.png" alt=""></div>
              <div>适用广泛</div>
              <div>覆盖多种中标类型新企业也可申请</div>
            </div>
            <div class="advance-item">
              <div><img src="./../assets/advanced_ico3.png" alt=""></div>
              <div>手续简便</div>
              <div>纯线上一键申请无需补充额外材料</div>
            </div>
            <div class="advance-item">
              <div><img src="./../assets/advanced_ico4.png" alt=""></div>
              <div>灵活取还</div>
              <div>支持6个月先息后本按日计息随借随还</div>
            </div>
          </div>
        </div>
      </div>
      <div class="containter">
        <div class="advance-box">
          <div class="title">常见问题</div>
          <div class="question">
            <p>中标贷是一款什么产品？</p>
            <p>答：中标贷是为小微经营者提供的一款高额度、广覆盖、无抵押、手续简、秒放款的基于中标项目的信贷产品，缓解供应商资金压力，解决供应商资金周</p>
          </div>
          <div class="question">
            <p>满足什么条件可以申请？</p>
            <p>答：只要拥有政府采购中标项目即可申请（新成立企业也可申请）。</p>
          </div>
          <div class="question">
            <p>申请需要提供什么资料？</p>
            <p>答：无需提供任何额外材料，纯线上开户，3分钟完成</p>
          </div>
          <div class="question">
            <p>资金使用期限最长是多久？</p>
            <p>
              答：目前我们的授信额度有效期为3个月（即额度审批后三个月内可支用且支持随借随还、循环使用），支用完成后6个月内归还（归还时如距离额度审批完成超过3个月，则不可再借），理论上您的资金使用期限从额度审批完成之日起计算可长
            </p>
          </div>
          <div class="question">
            <p>额度是如何测算的？</p>
            <p>答：通常情况下，额度与您的中标贷金额相关，一般不超过中标金额的3折。但授信额度除了跟中标合同金额相关外，还和您的信用等级、中标合同类型以及您是否存在其他贷款等因素相关。</p>
          </div>
          <div class="question">
            <p>提交申请后什么时候可以获得额度？</p>
            <p>
              答：一般情况下，额度为授信审批系统自动审批，最快1分钟内完成审批；部分特殊情况（如中标金额较大、服务类中标项目等）我们将通过人工审批额度，预计1个工作日内审批完成。审批完成后，我们将通过短信通知企业法定代表人，请注意查看
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- 授信弹窗 -->
    <el-dialog title="授信及支用流程" :visible.sync="dialogVisible" width="300px" :show-close="false" class="giveDialog">
      <div class="dialog-modal">
        <div>第一步:选择您的项目;</div>
        <div>第二步:一般情况下在您选择项目后将实时产生授信额度，部分客户需要人工审批(预计1个工作日)，授信完成后，您可以看到“可用额度”此时可以“支用”贷款;</div>
        <div>第三步: .开通相关银行账户(如果您已开通相关银行账户，则无需开户) ,预计30分钟。</div>
        <div>第四步:完成支用最快1-3分钟到账。</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="toAddProject">知道啦</el-button>
      </span>
    </el-dialog>
    <foot></foot>
  </div>
</template>

<script>
export default {
  name: 'advance',
  data() {
    return {
      dialogVisible: false,
    }
  },
  created() {

  },
  mounted() {
  },
  destroyed() {
  },
  methods: {
    //立即申请
    apply() {
      if (localStorage.getItem('token')) {
        this.dialogVisible = true
      } else {
        this.$alert('请先登录!', '提示', {
          confirmButtonText: '确定',
          callback: action => {
          }
        });
      }
    },
    //跳转添加项目
    toAddProject() {
      this.dialogVisible = false
      setTimeout(() => {
        this.$router.push({ path: '/apply', query: {} })
      }, 500)
    },
  },
  computed: {

  }
}
</script>

<style lang="less" scoped>
.product-containter {
  background: #F0F2F5;
  min-height: 100vh;

  .product-body {
    padding: 20px 0;

    .product-apply {
      box-sizing: border-box;
      width: 60%;
      height: 260px;
      margin: 0 auto 10px;
      background: url(./../assets/advance.png)no-repeat 0 0;
      background-size: 100%;
      color: #fff;
      font-size: 18px;
      position: relative;
      .apply-btn{
        position: absolute;
        left: 240px;
        bottom: 70px;
        img{
          width: 100px;
        }
      }
      .apply-btn:hover{
        cursor: pointer;
      }
      .product-left {
        margin: 20px 50px;

        div:first-child {
          margin-bottom: 10px;
          font-weight: bold;
          font-size: 26px;
        }
      }

      .product-right {
        margin: 50px 80px 0 0;
        background: url(./../assets/btn_bg.png)no-repeat 0 0;
        background-size: 100%;
        width: 180px;
        height: 100px;
        padding-left: 45px;
        box-sizing: border-box;
        line-height: 40px;
        font-size: 15px;
      }

      .product-right:hover {
        cursor: pointer;
      }
    }

    .containter {
      padding: 0;
      border-radius: 20px;
      min-height: 0;
      margin-bottom: 10px;

      .advance-box {
        text-align: center;

        .title {
          text-align: center;
          display: inline-block;
          padding: 5px 50px;
          border-radius: 0 0 30px 30px;
          font-size: 24px;
          color: #00abff;
          vertical-align: top;
          background-color: #ddf4ff;
        }

        .content {
          padding: 20px 25% 40px;

          .left {
            div:first-child {
              color: #333;
              font-size: 40px;

              span {
                color: #1677FF;
                font-size: 80px;
              }
            }

            div:nth-child(2) {
              color: #999;

              span {
                color: #1677FF;
              }
            }

            div:last-child {
              color: #1677FF;
            }
          }

          .right {
            img {
              width: 100px;
            }
          }
        }

        .advance-list {
          padding: 30px 40px 50px;

          .advance-item {
            div:nth-child(2) {
              margin-bottom: 5px;
              color: #333;
              font-size: 20px;
            }

            div:last-child {
              padding: 0 57px;
              color: #999;
              font-size: 17px;
            }
          }
        }

        .question {
          text-align: left;
          padding: 0 80px 10px;

          p:first-child {
            color: #333;
            font-size: 18px;
            line-height: 0;
          }

          p:last-child {
            line-height: 26px;
            margin-top: 5px;
            font-size: 16px;
            color: #666;
          }
        }
      }
    }
  }

  .giveDialog {
    .dialog-modal {
      padding: 10px;
      box-sizing: border-box;
      border-radius: 5px;
      border: 1px solid #ccc;
      box-shadow: 0 0 10px #ccc;
      font-size: 14px;
      line-height: 28px;
    }

    .el-dialog__header {
      text-align: center;
    }

    .el-dialog__body {
      padding: 0 20px 0 !important;
    }

    .el-dialog__footer {
      text-align: center;
    }
  }
}
</style>
